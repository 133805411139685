.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.form-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #555;
}

.form-input {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}

.message {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #4caf50;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.table th,
.table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.table tr:hover {
  background-color: #e8e8e8;
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .form-label {
    width: 100%;
  }

  .table {
    font-size: 0.9rem;
  }
}
