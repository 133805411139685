@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);

  &>div {
    padding: 0;
  }
}

.footer {
  /* max-width: var(--contentMaxWidthPages); */

  margin: 0 auto;
  padding: 24px 32px;

  & a {
    color: var(--colorGrey700);
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
  margin-bottom: 24px;
}

.content {
  display: grid;
   @media (min-width: 1100px) {
     grid-template-columns: 1fr 1fr 1fr 1fr;
     grid-column-gap: 16px;
   }
  @media (min-width: 425px) and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
}

.footerLogosList {
  @media (min-width: 425px) and (max-width: 1100px) {
    border-top: var(--colorGrey100) 1px solid;
    padding-top: 18px;
  }
  &:nth-child(2n+1) {
    @media (max-width: 425px) {
      border-top: var(--colorGrey100) 1px solid;
      padding-top: 18px;
      margin-bottom: 0;
    }
  }
}

.footerOfficialWrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  @media (min-width: 1100px) {
    padding-bottom: 25%;
  }
  @media (max-width: 1100px) {
    padding-bottom: 25%;
  }
}

.footerOfficialLogo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 100%;
  margin: auto;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  border-top: var(--colorGrey100) 1px solid;
  padding-top: 18px;

  & a {
    padding: 0;
  }

  &>div {
    display: flex;
    gap: 18px;

    @media (--viewportMedium) {
      gap: 24px;
    }
  }

  & svg {
    height: 18px;

    @media (--viewportMedium) {
      height: 24px;
    }
  }
}
