@import '../../../styles/customMediaQueries.css';

.rootInformationMenu {
    width: 24px;
    height: 32px;
}

.languageMenuContent {
    min-width: 200px !important;
    max-width: 300px;
    padding: 8px 0;
}

/* Estilos para los enlaces NamedLink */
.languageMenuItem {
    composes: textSmall from global;
    font-weight: var(--fontWeightSemiBold);
    position: relative;
    display: block;
    text-wrap: nowrap;
    cursor: pointer;
    /* Dimensions */
    width: 100%;
    margin: 0;
    padding: 12px 24px;

    /* Layout details */
    color: var(--colorGrey700);
    text-align: left;
    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--colorBlack);
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.05);
    }

    @media (--viewportMedium) {
        margin: 0;
    }
}

.languageMenuItem a {
    color: var(--colorGrey700); 
    transition: color 0.3s ease; 

    &:hover {
        color: var(--marketplaceColor); /* Color azul al pasar el ratón */
        text-decoration: none; /* Eliminar la raya del link */
    }
}

@media (max-width: 767px) { 
    .languageMenuLabel {
        margin-left: 8px;
    }
}
