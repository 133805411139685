.container {
  width: 100%;
  /* max-width: 1450px; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundOverlay::before,
.backgroundOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.backgroundOverlay::before {
  left: 0;
  background-color: #f3971b;
  transform: skew(-45deg) translateX(-50%);
}

.backgroundOverlay::after {
  right: 0;
  background-color: #2a9d90;
  transform: skew(-45deg) translateX(50%);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.closeButton {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .container {
    border-radius: 0;
  }
}
