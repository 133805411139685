@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border: var(--colorGrey100) 1px solid;
  border-radius: var(--borderRadiusMedium);
  /* background-color: var(--colorGrey50); */
  height: -moz-fit-content;
  height: fit-content;
  transition: var(--transitionStyleButton);

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowLight);
  }


}

.aspectRatioWrapper {
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
}

.rootForImage {
  border-top-left-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);

  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;

}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);


  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textXSmall from global;
  font-weight: var(--fontWeightRegular);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;
  line-height: 1.175em;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  padding: 14px 18px;
  color: var(--colorGrey700);
  display: flex;
  flex-direction: column;
}


.title {
  /* Font */
  composes: textMedium from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  line-height: 1.2em;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favoriteIcon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  transition: var(--transitionStyleButton);
  margin-left: 12px;

  &:hover {
    background-color: var(--colorGrey100);
  }
}

.description {
  composes: textSmall from global;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Establece el número máximo de líneas a mostrar */
  overflow: hidden;
  margin: 8px 0;
}

.bottomInfo {
  border-top: var(--colorGrey100) 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 14px 18px;
}


.authorAvatar {
  flex-shrink: 0;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey700);
  margin: 0;
  justify-content: center;
  line-height: 1.175em;


  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* Establece el número máximo de líneas a mostrar */
    overflow: hidden;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.ratings {
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightRegular);
  vertical-align: middle;

  & b {
    font-weight: var(--fontWeightSemiBold);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* Establece el número máximo de líneas a mostrar */
    overflow: hidden;
  }
}

.iconStar {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  overflow: visible !important;
}