/* Common styles */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
  font-weight: bold;

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  
}

/* Specific styles */
.h1 {
  font-size: 35px;

  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .h1 {
    font-size: 26px;
  }
}

  /* Ajuste de margen superior */
  .h2 + p,
  .h2 + a,
  .h2 + ul,
  .h2 + ol,
  .h2 + code,
  .h2 + div,
  .h2 + h1,
  .h2 + h2,
  .h2 + h3,
  .h2 + h4,
  .h2 + h5,
  .h2 + h6 {
    margin-top: 12px; /* Por ejemplo, ajusta el margen según tus necesidades */
  }

.h2 {
  font-size: 26px;
  color: #279d8e;
}

@media only screen and (max-width: 600px) {
  .h2 {
    font-size: 23px;
    color: #279d8e;
  }
}

.h3 {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .h3 {
    font-size: 18px;
  }
}

.h4 {
  font-size: 21px;
  text-align: center;
  color: #f59700;
}

.h3,
.h4,
.h5,
.h6 {
  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 8px;
  }
}

.h6 {
  font-size: 21px;
  color: #279d8e;
  text-transform: none;
}