@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.tab {}

.link {
  display: flex;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorGrey700);
  padding: 8px 12px;
  border-radius: 50px;
  align-items: center;

  font-weight: var(--fontWeightBold);

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

}

.selectedLink {
  background-color: var(--colorAttention);
  color: var(--colorWhite);
  height: min-content;

  &:hover {
    color: var(--colorWhite);
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
  font-weight: var(--fontWeightRegular);
}

.hasError {
  color: var(--colorFail);

}