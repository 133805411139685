@import '../../../styles/customMediaQueries.css';

.rootLanguageIcon {
    width: 24px;
    height: 32px;
}

.languageMenuLabel {
    padding: 0;
}

.languageMenuContent {
    min-width: 200px !important;
    max-width: 300px;
    padding: 8px 0;
}

.languageMenuItem,
.languageMenuItemSelected {
    composes: textSmall from global;
    font-weight: var(--fontWeightSemiBold);
    position: relative;
    display: block;
    text-wrap: nowrap;
    cursor: pointer;
    /* Dimensions */
    width: 100%;
    margin: 0;
    padding: 12px 24px;

    /* Layout details */
    color: var(--colorGrey700);
    text-align: left;
    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--colorBlack);
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.05);
    }

    @media (--viewportMedium) {
        margin: 0;
    }
}

.languageMenuItemSelected {
    color: var(--marketplaceColor);
    cursor: unset;

    &:hover {
        color: var(--marketplaceColor);
        background: none;
    }
}