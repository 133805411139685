* {
  box-sizing: border-box;
}

.main_accordion_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
}
.AccordionContainer {
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(50% - 40px);
}
.accordion {
  border-bottom: 1px solid #f0efef;
  height: auto;
  padding: 5px 20px;
  overflow: hidden; /* Ensure content doesn't overflow when closing */
  transition: height 0.3s ease-in-out; /* Add smooth transition for height change */
  width: 100%;
}
.active_accordion {
  border-bottom: 1px solid #f0efef;
  border-top: 2px solid #279d8e;
}

.accordion-title {
  /* background-color: #f1f1f1; */
  padding: 10px 0;
  padding-right: 10px;
  cursor: pointer;
  user-select: none;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 30px;
}
/* .accordion-title:hover {
  background-color: #fafafa;
} */

.accordion:hover {
  background-color: #fafafa;
}

.accordion-content {
  padding: 0;
  /* border-top: 1px solid #F0EFEF; */
  min-width: 100%;
  max-width: 100%;
  font-weight: 400;
  font-size: 15px;
}
.active_accordion_title {
  color: #000;
}

.rotate-down {
  transform: rotate(90deg);
}

.rotate-right {
  transform: rotate(0deg);
}

.rotate-down,
.rotate-right {
  transition: transform 0.3s ease-in-out; /* Adjust timing and easing as needed */
}

.rotate_icon {
  font-size: 20px !important;
  min-width: 30px;
}

@media screen and (max-width: 1200px) {
  .accordion {
    width: 100%;
  }
  .main_accordion_container {
    flex-direction: column;
  }
  .AccordionContainer {
    width: 100%;
  }
}
