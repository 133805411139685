@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  height: 100%;
  align-items: center;
  flex-shrink: 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }

  &:hover .logo {
    transform: scale(.925);
  }


}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: 35px;
  width: 95px;
  max-width: 188px;
  object-fit: cover;
  object-position: center;
  transition: transform ease-out 100ms;

  @media (--viewportMedium) {
    width: 108px;
    height: 40px;
  }
}