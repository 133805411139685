* {
  padding: 0;
  margin: 0;
}
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 500px;
  height: auto;
  min-height: auto;
  z-index: 1000;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

#cookie_title {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}
.buttons_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .cookie-consent {
    width: calc(100% - 20px);
    height: auto;
    min-height: 400px;
    overflow: auto;
  }
  .buttons_container {
    flex-direction: column;
  }
  .buttons_container button:nth-child(1) {
    order: 2;
  }
  .buttons_container button:nth-child(2) {
    order: 1;
  }
}
