@import '../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
  margin-bottom: 24px;
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.mobileInput {
  /* Font */
  composes: h2 from global;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  /* height: var(--topbarHeightDesktop); */
  border: none;

  display: flex;
  width: 18px;
  align-self: stretch;
  background-color: var(--colorWhite);
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  /* height: var(--topbarHeightDesktop); */
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */
  min-height: calc(100vh - 144px);
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: 38px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.datesSearchWrapper {
  position: relative;
  display: flex;
  width: 100%;
  background-color: var(--colorWhite);
  /* top: 200px; */
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--colorGrey700);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSvgCalendar {
  stroke: var(--colorGrey700);
  fill: var(--colorWhite);
}

.searchButton,
.mobileSearchButton {
  border: none;
  padding: 7px;
  background-color: var(--marketplaceColor);
  border-radius: 15px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.mobileSearchButton {
  width: calc(100% - 48px);
  border-radius: var(--borderRadiusMedium);
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  color: var(--colorWhite);
  height: 50px;
  margin: 24px;
}

.searchIcon {
  display: flex;

  & svg {
    width: 16px;
    height: 16px;

    & g {
      stroke: white;
      stroke-width: 2.5px;
    }
  }
}

.DateRangeInput {
  border: none;
  padding: 0;
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.search_form_className {
}
