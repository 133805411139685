.root {
    composes: marketplaceTinyFontStyles from global;
    margin: 0;
    background-color: var(--colorWhite);
    position: absolute;
    top: 18px;
    left: 18px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 12px 4px 8px;
    border-radius: var(--borderRadiusLarge);
    font-weight: var(--fontWeightSemiBold);
    box-shadow: var(--boxShadowLight);

    & svg {
        height: 16px;
        max-width: 16px;
        stroke: var(--marketplaceColor);
    }
}