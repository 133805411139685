.input {
  display: none;
  padding: 20px;
}

.container {
  width: 100%;
  height: auto;
}
/* 
.container > div {
  background-color: red;
  height: 100px;
}
.container > div > div div > div > div > div div > div > input {
  background-color: blue;
  height: 100px;
}

.container > div > div > div > div > div div > div > input {
  background-color: blue;
  height: 100px;
}

.container > div > div > div div > div > input {
  background-color: blue;
  height: 100px;
}

.container > div > div div > div > input {
  background-color: blue;
  height: 100px;
}
.container > div > div > div > input {
  background-color: blue;
  height: 100px;
}

.container > div > div > input {
  background-color: blue;
  height: 100px;
}

.container > div > input {
  background-color: blue;
  height: 100px;
}

.container > input {
  background-color: blue;
  height: 100px;
} */
