/* General styles for the top bar */
.topBar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

/* Warning message specific styles */
.warningMessage {
  color: #721c24; /* Dark red text */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.warningMessage::before {
  content: '⚠ '; /* Adds a warning icon (or emoji) before the message */
  font-size: 18px;
}
.containerBar {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f8d7da; /* Light red background */
  border: 1px solid #f5c6cb; /* Border color for contrast */
  padding: 0 20px;
}

.close_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  text-align: center;
  background-color: none;
  border: none;
  outline: none;
  font-size: 80px;
  cursor: pointer;
  /* position: absolute; */
  /* top: 10px;
  right: 10px; */
}

.close_button > svg {
  font-size: 80px;
}
.svg {
  font-size: 80px;
}
