@import '../../../styles/customMediaQueries.css';

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;


}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 24px;
  max-width: var(--contentMaxWidth);

  @media (--viewportLarge) {
    padding: 24px 36px;
  }

}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--colorGrey100);
  padding: 12px 24px;
  overflow-x: auto;
  position: -webkit-sticky;
  position: sticky;
  top: var(--topbarHeight);
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    top: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    padding: 12px 36px;
  }
}