.main {
  width: calc(90% - 20px);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 50px auto;
  background-color: #fff;
  padding: 50px 0;
  border-radius: 50px;
  max-width: 1500px;
}
.main_page_content_containe {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding: 0 50px;
  justify-content: space-between;
  align-items: flex-start;
}
.container_AccordionContainer {
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
}
.imageFAQS {
  width: 600px;
  height: 600px;
  /* background-image: url('https://img.freepik.com/free-vector/faqs-concept-illustration_114360-5185.jpg?t=st=1718365179~exp=1718368779~hmac=ff85729d4c9f7ba82dd3ed8a567882f0498c544d07c83471092b72bbff17f02e&w=740'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 84px 88px -17px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 1200px) {
  .main_page_content_containe {
    flex-direction: column;
  }
  .container_AccordionContainer {
    width: 100%;
  }
  .imageFAQS {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .main {
    /* background-color: red; */
    width: calc(100% - 20px);
  }
  .main_page_content_containe {
    padding: 1px;
  }
}
