/* Modern Black Button */
#modern_black_button,
#modern_white_button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #ffffff00;;
}


.modern_black_button:hover {
  background-color: #333;
}

.modern_black_button:focus {
  outline: none;
}

#modern_white_button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
